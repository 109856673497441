import "utils/airbrake";
import { ahoy } from "utils/ahoy";

import "utils/polyfills";
import "utils/rails-stuff";

import "images/report-it.svg";

import { ResizeObserver } from "@juggle/resize-observer";
import "posts-parts/iframe-dynamic-height";

import { debounce, throttle } from "lodash";
import { autorun, makeAutoObservable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";

// import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import { LeafletMouseEvent } from "leaflet";
import markerIcon from "images/map-icon.svg";
// import markerIconRetina from "images/map-icon.svg";
import markerShadow from "images/map-icon_shadow.svg";
import { Spinner } from "components/svgs";

import positionIcon from "images/position.svg";
import searchIcon from "images/search.svg";
import helpIcon from "images/help-white.svg";
import { createRoot } from "react-dom/client";

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  // iconRetinaUrl: markerIconRetina,
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

type helpKey = "subject" | "description" | "date_of_incident" | "location" | "";
type Suggestion = {
  boundingbox: any;
  display_name: string;
  importance: number;
  lat: string;
  lon: string;
  osm_id: string;
  osm_type: string;
  place_id: string;
};
class Store {
  debug = false;
  latitude = +(document.getElementById("incident_lat") as HTMLInputElement)
    ?.value;
  longitude = +(document.getElementById("incident_lng") as HTMLInputElement)
    ?.value;
  searchTerm = "";
  suggestions: Suggestion[] = [];
  selected_suggestion?: Suggestion = undefined;
  requestState = "";
  osm_id?: string = undefined;
  osm_type = "";
  get prefixed_osm_id() {
    return `${this.osm_type.charAt(0).toUpperCase()}${this.osm_id ?? ""}`;
  }

  get searchViewboxParam() {
    if (!window.initData.searchViewbox) return "";
    return `&viewbox=${window.initData.searchViewbox}`;
  }

  locationInProgress = false;

  get spinning() {
    return this.locationInProgress;
  }

  currentHelpKey: helpKey = "";
  selfScrolLY = 0;
  outerScrolLY = 0;
  boundingClientRect?: DOMRect = undefined;
  get visibleTopOffset() {
    const outerTop = this.boundingClientRect?.top ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.selfScrolLY; // just touching it for subscription
    // alternative to below, use just the margin: window.getComputedStyle(document.body.firstElementChild).marginTop;
    const offset =
      document.body.firstElementChild?.getBoundingClientRect().top ?? 0;
    const offsetTop = outerTop + offset;
    if (offsetTop < 0) {
      return -offsetTop;
    }
    return 0;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const store = new Store();
window.store = store;

let polies: L.GeoJSON;

function initializeMap(element: HTMLDivElement) {
  if (!element) return undefined;
  const map = L.map(element).setView(
    window.initData?.mapCenter ?? [51.3569061, 12.3631721],
    window.initData?.mapZoom ?? 3,
  );
  map.attributionControl.setPrefix(false);

  map.zoomControl.setPosition("bottomleft");

  const OpenStreetMap_Mapnik = L.tileLayer(
    "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
  );
  OpenStreetMap_Mapnik.addTo(map);

  polies = L.geoJSON(
    {
      type: "MultiPoint",
      coordinates: [],
    } as GeoJSON.MultiPoint,
    {
      style: { color: "#297FB9" },
    },
  ).addTo(map);

  map.on("click", function (ev: LeafletMouseEvent) {
    const latlng = map.mouseEventToLatLng(ev.originalEvent);
    setLL("click", latlng.lat, latlng.lng);
  });
  return map;
}
const mapElement = document.querySelector<HTMLDivElement>(
  ".submit-incident-map",
);
const map = initializeMap(mapElement!);
if (map && mapElement) {
  const mapResizeObserver = new ResizeObserver((_entries) =>
    map.invalidateSize(),
  );
  mapResizeObserver.observe(mapElement);

  let currentLocationMarker: L.Marker | undefined;
  autorun(() => {
    if (currentLocationMarker) {
      map.removeLayer(currentLocationMarker);
    }
    currentLocationMarker = L.marker([store.latitude, store.longitude]).addTo(
      map,
    );
  });
}

type IframeSizeScrollMessage = {
  scrollY: number;
  getBoundingClientRect: DOMRect;
};

const updateBoundingClientRect = throttle(
  (event: { data: IframeSizeScrollMessage }) => {
    if (event.data.getBoundingClientRect) {
      store.boundingClientRect = event.data.getBoundingClientRect;
    }
    if (event.data.scrollY) {
      store.outerScrolLY = event.data.scrollY;
    }
  },
  200,
);

window.addEventListener("message", updateBoundingClientRect, false);

let last_known_scroll_position = 0;
let ticking = false;

window.addEventListener("scroll", function (_e) {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function () {
      store.selfScrolLY = last_known_scroll_position;
      ticking = false;
    });

    ticking = true;
  }
});
// notifyIFrames(window.scrollY);

function getLocation() {
  if (navigator.geolocation) {
    store.locationInProgress = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        store.locationInProgress = false;
        setLL(
          "geolocation",
          position.coords.latitude,
          position.coords.longitude,
          { accuracy: position.coords.accuracy },
        );
        //   loctestmarkercircle = L.circle(e.latlng, radius).addTo(map);

        ahoy.track("geolocation", {
          status: "success",
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          },
          user_agent: window.navigator.userAgent,
        });
      },
      (err) => {
        store.locationInProgress = false;
        ahoy.track("geolocation", {
          status: "error",
          code: err.code,
          message: err.message,
          user_agent: window.navigator.userAgent,
        });

        alert(
          `Could not get current location. Code: ${err.code}  Message: ${err.message}`,
        );
      },
    );
  } else {
    ahoy.track("geolocation", {
      status: "not_supported",
      user_agent: window.navigator.userAgent,
    });
    alert("It looks like your browser does not support location.");
  }
}

//searchterm_suggestions
autorun(() => {
  runInAction(() => (store.requestState = "pending"));
  if (store.searchTerm.length > 0) {
    console.log("requesting suggestions for ", store.searchTerm);
    fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${store.searchTerm}${store.searchViewboxParam}`,
    )
      .then((response) => response.json())
      .then((json) => {
        console.log("received", json.length);
        runInAction(() => (store.suggestions = json));
        if (store.suggestions.length > 0) {
          runInAction(() => (store.requestState = "resolved"));
        } else {
          fetch(`/did-you-mean?q=${store.searchTerm}`)
            .then((response) =>
              response.status === 204
                ? {}
                : (response.json() as Promise<{ did_you_mean: unknown }>),
            )
            .then((json: any) => {
              const dym = json["did_you_mean"];
              console.log(
                `Nothing found for "${store.searchTerm}", trying did-you-mean: "${dym}"`,
              );
              if (dym && dym.length > 0) {
                runInAction(() => (store.searchTerm = dym));
              } else {
                runInAction(() => (store.requestState = "resolved"));
              }
            })
            .catch((err) => {
              console.log(
                "Error (2) fetching results for location search ",
                err,
              );
              runInAction(() => {
                store.requestState = "error";
                store.suggestions = [];
              });
            });
        }
      })
      .catch((err) => {
        console.log("Error fetching results for location search ", err);
        runInAction(() => {
          store.requestState = "error";
          store.suggestions = [];
        });
      });
    ahoy.track("getSuggestions", { searchTerm: store.searchTerm });
  } else {
    runInAction(() => {
      store.requestState = "resolved";
      store.suggestions = [];
    });
  }
});

reaction(
  () => store.selected_suggestion,
  (sug) => {
    if (!sug) return;
    setLL("suggestion", parseFloat(sug["lat"]), parseFloat(sug["lon"]), {
      bounds: sug["boundingbox"],
    });

    store.osm_id = sug["osm_id"];
    store.osm_type = sug["osm_type"];
  },
);

//osm_id_details
autorun(() => {
  if (!store.prefixed_osm_id) return;
  fetch(
    `https://nominatim.openstreetmap.org/lookup?format=json&osm_ids=${store.prefixed_osm_id}&polygon_geojson=1&addressdetails=1&extratags=1&namedetails=1&polygon_threshold=0.01`,
  )
    .then((response) => response.json())
    .then((json) => {
      console.log("received", json.length);
      console.log(json);
      const loc = json[0];
      if (loc && loc["geojson"]) {
        const gj = loc["geojson"];
        setTimeout(() => {
          // add polies later because if they're there too early they look very blurry
          polies.addData(gj);
        }, 300);
      }
    })
    .catch((err) => {
      console.log("Error fetching details for selected location", err);
    });
});

// autorun(() => {
//   if (!store.latitude || !store.longitude) return;
//   fetch(
//       `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${store.latitude}&lon=${store.longitude}&addressdetails=1&extratags=1&namedetails=1&polygon_geojson=1`
//     ).then((response) => response.json())
//     .then((json) => {
//       console.log("LL Reverse", json);
//     });
// });

const setLL = (
  src: string,
  lat: number,
  lon: number,
  opts: { bounds?: any; zoom?: number; accuracy?: number } = {},
) => {
  const { bounds, zoom /*, accuracy*/ } = opts;
  ahoy.track("setLL", { src, lat, lon });

  runInAction(() => {
    store.latitude = lat;
    store.longitude = lon;
  });

  (document.getElementById("incident_lat") as HTMLInputElement).value =
    lat.toString();
  (document.getElementById("incident_lng") as HTMLInputElement).value =
    lon.toString();
  // document.getElementById("incident_zoom").value = zoom;
  Array.prototype.forEach.call(
    document.getElementsByClassName("errors-for-lat"),
    (e) => e.classList.add("resolved"),
  );
  const label = document.querySelector<HTMLLabelElement>(
    "label[for=incident_lat]",
  );
  label?.parentElement?.classList.remove("field_with_errors");

  if (!map) return;
  if (bounds && bounds.length == 4) {
    map.flyToBounds([
      [bounds[1], bounds[2]],
      [bounds[0], bounds[3]],
    ]);
  } else if (zoom) {
    map.flyTo([lat, lon], zoom);
  } else {
    map.flyTo([lat, lon], Math.max(map.getZoom(), 14));
  }
  polies.clearLayers();
};

const HelpContent = observer(() =>
  store.currentHelpKey ? (
    <div
      class="absolute left-0 right-0 top-0 z-abovemap flex
        items-center rounded-none bg-black bg-opacity-66 p-2 text-sm text-white opacity-100 transition-all duration-300"
      style={{
        transform: `translateY(${store.visibleTopOffset}px`,
      }}
    >
      <style>
        #error_explanation {"{"}opacity:0{"}"}
      </style>
      <img src={helpIcon} class="m-4 h-10 w-10" alt="Help" />
      {helpTexts[store.currentHelpKey]}
    </div>
  ) : null,
);

const App = observer(() => (
  <div class="location-searchbar">
    <HelpContent />
    <div class="searchbar-input init" role="search">
      <div class="form-field tooltipx flex items-center py-2">
        <img alt="" src={searchIcon} class="absolute ml-1 h-5" />
        <form onSubmit={(e) => e.preventDefault()} class="w-full">
          <input
            id="search-location"
            name="search-location"
            type="search"
            maxLength={250}
            placeholder="Search for addresses, place names, or coordinates..."
            onInput={debounce((evt) => {
              evt.preventDefault();
              runInAction(() => (store.searchTerm = evt.target.value));
            }, 500)}
            onFocus={(evt) =>
              runInAction(() => (store.searchTerm = evt.target.value))
            }
            onBlur={(_evt) =>
              setTimeout(() => {
                runInAction(() => (store.searchTerm = ""));
              }, 500)
            }
            autoComplete="off"
            class="w-full appearance-none rounded-none border-b border-steel-blue-500 bg-transparent py-2 pl-8 pr-1 outline-none focus:border-transparent focus:shadow-outline1"
          />
        </form>
        <a
          onClick={() => getLocation()}
          title="Use your current location"
          class="cursor-pointer"
        >
          {store.spinning ? (
            <span class={`spinner ${store.spinning ? "" : "off"}`}>
              <Spinner />
            </span>
          ) : (
            <img
              alt="Use your current location"
              src={positionIcon}
              class="m-2 mr-0 h-10 rounded-md hover:bg-steel-blue-100"
            />
          )}
        </a>
      </div>
      <div
        class="relative z-10 ml-6 mr-12"
        style={{ maxWidth: "calc(100% - 4.5rem);" }}
      >
        <div
          class="absolute w-full rounded-b-lg bg-white bg-opacity-75
        px-2"
        >
          {store.suggestions.length > 0 ? (
            <ul class=" mb-2 font-light">
              {store.suggestions.map((sug) => (
                <li
                  key={sug.place_id}
                  onClick={() => {
                    runInAction(() => {
                      store.selected_suggestion = sug;
                      store.searchTerm = "";
                    });
                  }}
                  class="h-6 cursor-pointer truncate hover:bg-steel-blue-200 hover:bg-opacity-50"
                >
                  <div
                    style={{ height: `${sug["importance"] * 67}%` }}
                    class="bottom-0 mr-2 inline-block w-1 bg-steel-blue-500"
                  />
                  {sug["display_name"]}
                  {store.debug && JSON.stringify(sug)}
                </li>
              ))}
            </ul>
          ) : store.requestState == "resolved" ? (
            store.searchTerm.length > 0 && <p>No search results</p>
          ) : store.requestState == "pending" ? (
            <p>Loading...</p>
          ) : (
            <p class="text-punch-500">
              An error has occurred. Please try again.
            </p>
          )}
        </div>
      </div>
    </div>
  </div>
));

const helpTexts = {
  subject: <p>Please tell us what happened in a few words</p>,
  description: (
    <div>
      <p>Please describe what happened in more detail. This can include:</p>
      <ul class="list-disc p-8">
        <li>Who was attacked</li>
        <li>Who was the source of the threat</li>
        <li>When the attack took place</li>
        <li>What happened</li>
        <li>Where did the attack take place</li>
      </ul>
      <p>
        Please include any details about the background, context or important
        circumstances Please include any links or sources you have (not
        required, but very helpful for us).
      </p>
    </div>
  ),
  date_of_incident: (
    <p>
      When did it happen?
      <br />
      If you don’t know the exact date, please provide it as precisely as
      possible. If you only know the month, set it to the 1st day of that month
      and put a note in the description explaining that the exact date is
      unknown.
    </p>
  ),
  location: (
    <p>
      Please tell us where the incident happened. You can either search for an
      address or click on the map. If you do not know the exact address please
      include the city or country where the incident happened.
    </p>
  ),
  contact: (
    <div>
      <p>
        Let us know how we can reach you. This will <em>not</em> be published.
        This can include:
      </p>
      <ul class="list-disc p-8">
        <li>Phone number</li>
        <li>E-mail address</li>
        <li>Twitter handle</li>
        <li>or whatever you find suitable</li>
      </ul>
      <p>
        Please note that it is not compulsory to submit contact details.
        However, we would greatly appreciate it if you could share an email or
        telephone number so we can contact you if further information is needed.
      </p>
    </div>
  ),
};

let helpTimeout: NodeJS.Timeout | undefined;

function showHelp(e: MouseEvent | TouchEvent) {
  e.stopPropagation();
  e.preventDefault();
  if (helpTimeout) clearTimeout(helpTimeout);
  const element = e.target as HTMLElement;
  store.currentHelpKey = element.dataset.help as helpKey;
}

function hideHelp(e: MouseEvent | TouchEvent) {
  e.stopPropagation();
  e.preventDefault();
  helpTimeout = setTimeout(() => {
    store.currentHelpKey = "";
  }, 2000);
}

document
  .querySelectorAll<HTMLImageElement>("img.info-i")
  .forEach((imgElement) => {
    imgElement.addEventListener("mouseenter", showHelp);
    imgElement.addEventListener("touchstart", showHelp);
    imgElement.addEventListener("mouseleave", hideHelp);
    imgElement.addEventListener("touchend", hideHelp);
    imgElement.addEventListener("touchcancel", hideHelp);
  });

const searchbar_controls = document.querySelectorAll(".location-searchbar");
if (searchbar_controls.length) {
  const root = createRoot(searchbar_controls[0]);
  root.render(<App />);
}
document.querySelector(".latlon-fields")?.classList.add("hide-latlon");

function getCharacterLength(str: string) {
  // The string iterator that is used here iterates over characters,
  //  not mere code units, thus, emojis etc. count as 1 instead of more.
  return [...str].length;
}

const titleElement = document.getElementById(
  "incident_title",
) as HTMLInputElement;
if (titleElement) {
  titleElement.insertAdjacentHTML(
    "afterend",
    `<span id="incident_title_validator" class="float-right" title="maximum length of title is 100 characters"></span>`,
  );

  const titleElementValidator = document.getElementById(
    "incident_title_validator",
  );

  const updateMaxLengthIndicator = (e?: Event) => {
    const l = getCharacterLength(titleElement.value);
    const msgElements = document.getElementsByClassName("errors-for-title");
    if (!e && msgElements.length == 0) return;
    if (!titleElementValidator) return;
    if (titleElement) {
      const teParent = titleElement.parentElement;
      if (!teParent) return;
      if (l > 0 && l < 80) {
        titleElementValidator.innerHTML = "";
        teParent.classList.remove("field_with_errors");
        if (teParent.parentElement?.classList.contains("field")) {
          teParent.previousElementSibling?.classList.remove(
            "field_with_errors",
          );
        }
        Array.prototype.forEach.call(msgElements, (e) =>
          e.classList.add("resolved"),
        );
      } else if (l > 0 && l <= 100) {
        titleElementValidator.innerHTML = `<span class="text-curious-blue-500">${l} / 100</span>`;
        teParent.classList.remove("field_with_errors");
        if (teParent.parentElement?.classList.contains("field")) {
          teParent.previousElementSibling?.classList.remove(
            "field_with_errors",
          );
        }
        Array.prototype.forEach.call(msgElements, (e) =>
          e.classList.add("resolved"),
        );
      } else {
        if (l == 0) {
          titleElementValidator.innerHTML = `<span class="text-punch-500">should not be empty</span>`;
        } else {
          titleElementValidator.innerHTML = `<span class="text-punch-500">${
            100 - l
          }</span>`;
        }
        teParent.classList.add("field_with_errors");
        if (teParent.parentElement?.classList.contains("field")) {
          teParent.previousElementSibling?.classList.add("field_with_errors");
        }
        Array.prototype.forEach.call(msgElements, (e) =>
          e.classList.remove("resolved"),
        );
      }
    }
  };

  if (titleElement) {
    titleElement.addEventListener("input", updateMaxLengthIndicator);
    titleElement.addEventListener("blur", () =>
      titleElement?.parentElement?.classList.add("touched"),
    );
    titleElement.addEventListener("blur", updateMaxLengthIndicator);

    updateMaxLengthIndicator();
  }
}

const descriptionElement = document.getElementById(
  "incident_description",
) as HTMLTextAreaElement;
if (descriptionElement) {
  descriptionElement.insertAdjacentHTML(
    "afterend",
    `<span id="incident_description_validator" class="float-right" title="minimum length of description is 50 characters"></span>`,
  );

  const descriptionElementValidator = document.getElementById(
    "incident_description_validator",
  ) as HTMLSpanElement;

  const updateDescriptionLengthIndicator = (e?: Event) => {
    if (!descriptionElement) return;
    const l = getCharacterLength(descriptionElement.value);
    const msgElements = document.getElementsByClassName(
      "errors-for-description",
    );
    const deParent = descriptionElement.parentElement;
    if (!deParent) return;
    const hasTouchedOrHadError =
      deParent.classList.contains("touched") || msgElements.length > 0;
    if (!e && msgElements.length == 0) return;
    if (l >= 50) {
      descriptionElementValidator.innerHTML = "";
      deParent?.classList.remove("field_with_errors");
      if (deParent.parentElement?.classList.contains("field")) {
        deParent.previousElementSibling?.classList.remove("field_with_errors");
      }
      Array.prototype.forEach.call(msgElements, (e) =>
        e.classList.add("resolved"),
      );
    } else if (l > 40 && l < 50) {
      descriptionElementValidator.innerHTML = `<span class="${
        hasTouchedOrHadError ? "text-punch-500" : "text-curious-blue-500"
      }">${50 - l} to go</span>`;
      deParent.classList.add("field_with_errors");
      if (deParent.parentElement?.classList.contains("field")) {
        deParent.previousElementSibling?.classList.add("field_with_errors");
      }
      Array.prototype.forEach.call(msgElements, (e) =>
        e.classList.remove("resolved"),
      );
    } else {
      descriptionElementValidator.innerHTML = `<span class="${
        hasTouchedOrHadError ? "text-punch-500" : "text-curious-blue-500"
      }">${l} of at least 50 characters</span>`;
      deParent.classList.add("field_with_errors");
      if (deParent.parentElement?.classList.contains("field")) {
        deParent.previousElementSibling?.classList.add("field_with_errors");
      }
      Array.prototype.forEach.call(msgElements, (e) =>
        e.classList.remove("resolved"),
      );
    }
  };

  if (descriptionElement) {
    descriptionElement.addEventListener(
      "input",
      updateDescriptionLengthIndicator,
    );
    descriptionElement.addEventListener("blur", () =>
      descriptionElement?.parentElement?.classList.add("touched"),
    );
    descriptionElement.addEventListener(
      "blur",
      updateDescriptionLengthIndicator,
    );
    updateDescriptionLengthIndicator();
  }
}
