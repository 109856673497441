import { ResizeObserver } from "@juggle/resize-observer";

const parentFrame = window.parent;
if (parentFrame) {
  const notifyHeight = () => {
    console.log(
      "Notify height:",
      document.documentElement.scrollHeight || document.body.scrollHeight,
    );
    parentFrame.postMessage(
      {
        height:
          document.documentElement.scrollHeight || document.body.scrollHeight,
      },
      "*",
    );
  };

  const ro = new ResizeObserver((_entries) => {
    notifyHeight();
  });

  ro.observe(document.body);

  notifyHeight();
}
